<template>
  <div class="fah" v-loading="loading">
    <img src="@/assets/imgs/轮播 拷贝.png" alt="" height="400px" width="100%">

    <div class="vid" v-loading="videoLoading" element-loading-text="视频努力加载中，请稍等">
      <!-- type == 1 -->
      <div class="player-container" v-if="type == 1">
        <video id="example" @play="videoPlay" @timeupdate="Videotimeupdate" class="video-js vjs-default-skin vjs-big-play-centered vjs-v7 vjs-user-active" playsinline
          webkit-playsinline autoplay controls preload="auto" width="100%" height="100%" @loadeddata="cutOneImg($event)" ref="videoPlayer"
          data-setup='{"techOrder": ["html5","flash"]}'>
        </video>
        <div class="vjs-playlist playlist-container">

        </div>
      </div>
      <!-- type == 2 -->
      <img v-show="type == 2" :src="sourceDetail.courseImageUrl" alt="" width="100%" height="100%">
    </div>
    <div class="kecName">
      <div class="bti">
        <span>{{ sourceDetail.courseTitle }} </span>
        <span></span>
        <span v-if="type == 1" class="btiren">{{ sourceDetail.courseEnrollment }}人在观看</span>
        <span v-if="type == 2" class="btiren">已报名{{ sourceDetail.courseEnrollment }}人 </span>
      </div>
      <div class="jiage">
        <span>￥{{ sourceDetail.price }}/</span>
        <span style="color: red;">现价</span>
        <del> 原价{{ sourceDetail.originalPrice }}</del>

        <div @click="joinStudy">
          加入学习 >>
        </div>
        <p v-show="type == 2" style="color: #777777;font-size: 16px;margin:3px 0">地址:{{ sourceDetail.address }}</p>
        <p v-show="type == 2" style="color: #777777;font-size: 16px;">时间:{{ sourceDetail.startTime }}</p>
      </div>
    </div>
    <!-- 课程简介 -->
    <div class="kcJianj">
      <!-- 老师介绍 -->
      <div class="lsjj resouceAndconsultFz">
        <img src="@/assets/imgs/椭圆 2.png" class="tuoyuan" alt="">
        <span>老师简介 </span>
        <!-- <span> Teacher Introduction</span> -->
      </div>
      <!--老师  -->
      <div class="tchr">
        <img :src="sourceDetail.headSculptureUrl" alt="" width="100" height="100">
        <span>{{ sourceDetail.teacherName }}</span>
      </div>
      <div class="tchrJianj" style="margin-top: 20px; ">
        <span>{{ sourceDetail.teacherDetail}} </span>
        <!-- <span style="display: block" v-for="(item,index) in  sourceDetail.teacherDetail" :key="index">{{ item.stringModel }} </span> -->
      </div>

      <div class="lsjj resouceAndconsultFz">
        <img src="@/assets/imgs/椭圆 2.png" class="tuoyuan" alt="">
        <span>课程介绍 </span>
        <span> Course Introduction</span>
      </div>
      <!-- 简介 -->
      <div class="kcjj">
        <div v-for="(item, index) in sourceDetail.courseIntroduction">
          {{ item.resourcedetail }}
        </div>
      </div>
      <div class="lsjj resouceAndconsultFz">
        <img src="@/assets/imgs/椭圆 2.png" class="tuoyuan" alt="">
        <span>其他课程推荐 </span>
        <span> PingFang-SC-Medium </span>
      </div>
      <div class="kctj">
        <div class="resourceItem" style="width:22%" v-for="(item, index) in resourcelist" :key="item.courseId" :style="{ marginRight: item.isfour ? '0px' : '4%' }"
          @click="handclick(item)">
          <img class="pic" :src="item.courseImageUrl"></img>
          <div class="onebox" style="height: 20px;">
            <span class="priceone">{{ item.price }}</span>
            <span class="pricetwo"> 原价{{ item.originalPrice || '--' }} </span>
            <span class="favorable">{{ item.courseGreat }}好评</span>
          </div>
          <div class="twobox">
            <img class="time" src="@/assets/imgs/time.png"></img>
            <span class="timeLine">{{ item.startTime }} </span>
            <span class="buypeople"> {{ item.courseEnrollment }}已购</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 购买弹窗 -->
    <el-dialog :visible.sync="buyDialogVisible" :show-close="false" width="780px">

      <div class="tc">
        <div class="tcGuanbi">
          <img style="margin-right: 22%;margin-top: 9%;" src="@/assets/imgs/shanchuguanbicha(2)@2x.png" alt="" @click="buyDialogVisible = false">
        </div>
        <img style="width: 60%;margin: 15% 0 0 12%!important;" src="@/assets/imgs/tanchuang.png" alt="">
        <div style="position: absolute;
    top: 29%;
    left: 32%;
    z-index: 9;
    color: white;
    font-size: 26px;
    text-align: center;">
          <p>尊敬的</p>
          <p>{{ phonenumber}}</p>
        </div>
        <div class="tcText" style="left: 17%;">
          观看该视频需要产生购买，支付成功后方 可观看。<br>
          请点击下方即刻购买进行支付，支付成功 后可反复观看！
        </div>
        <div class="tcbtn" @click="jikeBuy" style="width: 60%;left: 12%;">
          即刻购买
        </div>
      </div>

    </el-dialog>
    <!-- 购买成功弹窗 -->
    <el-dialog :visible.sync="buyscccessDialogVisible" :show-close="false" width="780px">
      <div class="tc">
        <div class="tcGuanbi">
          <img style="margin-right: 22%;margin-top: 9%;" src="@/assets/imgs/shanchuguanbicha(2)@2x.png" alt="" @click="buyscccessDialogVisible = false">
        </div>
        <img class="zu2" style="width: 60%;margin: 15% 0 0 12%!important;" src="@/assets/imgs/组 2@2x.png" alt="">
        <div class="text1" style="left: 2%">购买成功</div>
        <div class="text1" style="top: 34%;left: -2%;">用户{{ phonenumber}}</div>
        <div class="tcText tcText2" style="left: 2%;">
          视频版权归 <span style="color: black;">指南针</span> 所有，请勿录制或下载
        </div>

      </div>

    </el-dialog>
    <!-- 领取弹窗 -->
    <el-dialog :visible.sync="dialogVisible" :show-close="false" width="780px">

      <div class="tc2">
        <div class="tcGuanbi">
          <img src="@/assets/imgs/shanchuguanbicha(2)@2x.png" alt="" @click="dialogVisible = false">
        </div>
        <img src="@/assets/imgs/底部@2x_.png" alt="">
        <div class="botBox">
          <div class="tc2Text">
            尊敬的用户您好,距离领取资格仅差一步了!
          </div>
          <div class="tc2Text2">
            完善听课人信息，即可获得听课资格：
          </div>
          <div class="tc2Text3">
            <el-input v-model="userName" :rows="5" type="text" placeholder="您的姓名" />
            <div :class="{tc2bag:userSex == '男'}" @click="userSex = '男'">男</div>
            <div :class="{tc2bag:userSex == '女'}" @click="userSex = '女'">女</div>
          </div>
          <div class="tc2Text3" style="top: 65%;margin-right: 10px;">
            <el-input v-model="buyPhone" :rows="5" type="text" placeholder="您的手机号" />
          </div>
          <div class="btn" @click="submitXX">
            确认信息
          </div>
        </div>
      </div>

    </el-dialog>
    <el-dialog custom-class="buyDialog" style="margin-top: 15vh!important;" :close-on-press-escape="false" :close-on-click-modal="false" :modal-append-to-body="false"
      :append-to-body="true" title="支付提示" :visible.sync="centerDialogVisible" width="50%" center @close="dialogClose">
      <div style="width:720px;display:flex;background: white;justify-content: space-around;padding-top:15px">
        <!-- 微信 -->
        <div style="height:275px;margin-right: 15px">
          <div style="width: 210px; height: 210px;margin: 0 auto;">
            <div v-if="payTimeFlag" style="padding-left: 24px;">
              剩余支付时间
              <span style="color: orange">{{ count }}</span>
            </div>
            <div style="text-align:center ;" v-if="payTimeFlag" ref="qrCode" id="qrCode"></div>
            <div v-if="resetPayflag" style="
                        z-index: 10;
                        width: 210px;
                        height: 210px;
                        background: rgba(0, 0, 0, 0.7);
                        color: white;
                        text-align: center;
                        cursor: pointer;
                        color: white;
                        box-sizing: border-box;
                        padding-top: 80px;
                      ">
              <p>二维码已过期</p>
              <p>请重新刷新</p>
            </div>
            <div style="margin-top: 10px;text-align: center;">
              <img src="@/assets/imgs/sao.png" style="width:20px ;vertical-align: top;" alt="">
              <span>微信扫一扫支付</span>
            </div>
          </div>
        </div>
        <!-- 支付宝 -->
        <div style="height:275px">
          <div style="height: 20px;">

          </div>
          <div style="width: 210px; height: 210px;margin:auto">
            <iframe :srcdoc="qianHtml" frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="no" width="210" height="210" style="overflow:hidden;">
            </iframe>
          </div>
          <div style=" text-align: center;">
            <img src="@/assets/imgs/sao.png" style="width:20px ;vertical-align: top;" alt="">
            <span>支付宝扫一扫支付</span>
          </div>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import { getCourseDetail, getCourseList, userHaspermissions, addofflinecourse, fileVide0 } from "@/api/resourceAndconsult"
import { REQWXPAY, REQZFBPAY, tradeQuery, tradeQueryZFB } from '@/api/pay.js'
import QRCode from 'qrcodejs2'
import axios from 'axios'
export default {
  name: 'courseItem',
  data() {
    return {
      videoSrc: '',
      player:null,
      resourcelist: [],
      loading: false,
      type: '',
      courseId: '',
      videoLoading: false,
      sourceDetail: {},
      userSex: '男',
      dialogVisible: false,
      userName: null,
      buyPhone: null,
      buyDialogVisible: false,
      buyscccessDialogVisible: false,
      phonenumber: '',
      centerDialogVisible: false,
      timer: null, // 轮询查询订单
      paytimer: null, // 5分钟倒计时的timer
      timerZFB: null, // 支付宝轮询
      orderNo: '',
      orderNoZFB: '',
      seconds: 300, // 5分钟的秒数
      count: '05分00秒',
      payTimeFlag: false,
      resetPayflag: false,
      qianHtml: ''
    };
  },
  watch: {
    '$route'(to, from) {
      this.redrus()

    }
  },
  mounted() {
    this.redrus(this.$route.query.type, this.$route.query.courseId)
    this.getOtherList()
    if (localStorage.getItem('phonenumber')) {
      this.phonenumber = localStorage.getItem('phonenumber').replace(localStorage.getItem('phonenumber').substring(3, 7), '****')
    }
  },

  methods: {
    // 完善听课人手机号
    submitXX() {
      if (!localStorage.getItem('phonenumber')) {
        const channel = new BroadcastChannel('share')
        channel.postMessage(true)
        // this.$message.warning('请先登录')
        return
      }
      let params = {
        courseId: this.courseId,
        userSex: this.userSex,
        userName: this.userName,
        buyPhone: this.buyPhone,
        userPhone: localStorage.getItem('phonenumber')
      }
      if (!params.userName) {
        this.$message.warning('请输入姓名')
        return
      }
      const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (!reg.test(params.buyPhone)) {
        this.$message.warning('请输入正确格式手机号')
        return
      }
      addofflinecourse(params).then(res => {
        if (res.code == 200) {
          this.dialogVisible = false;   //关闭当前弹框
          this.jikeBuy()   //开始支付
        }
      })
    },
    async joinStudy() {
      const phonenumber = localStorage.getItem('phonenumber')
      if (!phonenumber) {
        const channel = new BroadcastChannel('share')
        channel.postMessage(true)
        return
      }
      let params = { userphone: phonenumber, courseId: this.courseId }
      if (this.type == 1) {  //线上
        const { data: { permissions } } = await userHaspermissions(params);
        if (permissions == 2) {  //没有观看权限
          this.buyDialogVisible = true;
        } else {
          this.$message.success('您已成功购买该课程')
        }
      } else if (this.type == 2) { //线下
        this.dialogVisible = true
      }

    },
    // 视频播放的时候
    async videoPlay(e) {
      var videoEle = this.$refs.videoPlayer;
      const phonenumber = localStorage.getItem('phonenumber')
      if (!phonenumber) {
        const channel = new BroadcastChannel('share')
        channel.postMessage(true)
        //  this.$message.warning('请先登录')
        videoEle.pause()   //暂停视频播放
        videoEle.currentTime = 0;   //视频回到第0秒
        return
      }
      let params = { userphone: phonenumber, courseId: this.courseId }
      const { data: { permissions } } = await userHaspermissions(params)
      if (permissions == 2) {
        this.buyDialogVisible = true;
        videoEle.currentTime = 0;   //视频回到第0秒
        videoEle.pause()   //暂停视频播放
      }
    },
    // 视频进度发生改变时
    Videotimeupdate(e) {

    },
    // 截取视频第一帧作为封面
    cutOneImg(event) {

      const videoEle = event.target;
      videoEle.currentTime = 1;
      var canvas = document.createElement('canvas');
      canvas.width = 320;
      canvas.height = 150;
      canvas.getContext('2d').drawImage(videoEle, 0, 0, canvas.width, canvas.height);
      // 给封面图片
      videoEle.poster = canvas.toDataURL('image/png');
    },
    // 点击购买按钮
    jikeBuy() {
      this.centerDialogVisible = true;
      this.wxpay()
      this.zfbpay()
    },
    // 微信支付
    async wxpay() {
      const self = this
      this.centerDialogVisible = true
      this.resetPayflag = false
      this.payTimeFlag = true
      const obj = {
        phoneNumber: localStorage.getItem('phonenumber'),
        payType: '微信支付',
        productTitle: '课程名称后端自己去取',
        productType: '2',    //自主卡 智能卡 为 1，课程为2，咨询为3.
        productId: this.courseId       //自主卡 智能卡为 '', 课程为课程id，咨询为咨询id
      }
      await REQWXPAY(obj).then((res) => {
        self.seconds = 300
        self.Time()
        self.$nextTick(() => {
          const el = this.$refs.qrCode
          document.getElementById('qrCode').innerHTML = ''
          new QRCode(el, {
            width: 200,
            height: 200,
            text: res.data.codeUrl
          })
        })
        self.orderNo = res.data.orderNo
      })
      if (!this.timer) {
        this.timer = window.setInterval(async () => {
          const res = await tradeQuery({ orderNo: self.orderNo })
          if (res.code == 200) {
            this.clearAllTimer()
            // this.$message.success('支付成功')
            this.count = '05分00秒'
            this.payTimeFlag = false
            this.centerDialogVisible = false;   //关闭支付二维码弹框
            if (this.type == 1) {   //线上购买
              this.buyDialogVisible = false;      //关闭购买弹框
              this.buyscccessDialogVisible = true;  //支付成功弹框打开
            } else if (this.type == 2) {  //线下购买
              this.$alert('期待与您相聚在我们的线下课程', '购买成功', {
                confirmButtonText: '确定',
              });
              this.dialogVisible = false
            }
          }
        }, 2000)
      }
    },
    // 支付宝支付
    async zfbpay() {
      const self = this

      this.loadingZFB = true
      this.centerDialogVisible = true
      const obj = {
        phoneNumber: localStorage.getItem('phonenumber'),
        payType: '支付宝支付',
        productTitle: '课程名称后端自己去取',
        productType: '2',    //自主卡 智能卡 为 1，课程为2，咨询为3.
        productId: this.courseId       //自主卡 智能卡为 '', 课程为课程id，咨询为咨询id
      }
      await REQZFBPAY(obj).then((res) => {
        self.loadingZFB = false
        self.qianHtml = res.data.iframe
        self.orderNoZFB = res.data.outTradeNo
        return
        var form = res.data
        const div = document.createElement('div') // 创建div
        div.innerHTML = form // 将返回的form 放入div
        document.body.appendChild(div)
        document.forms[1].setAttribute('target', '_blank') // 新开窗口跳转
        document.forms[1].submit()
      })
      if (!this.timerZFB) {
        this.timerZFB = window.setInterval(async () => {
          const res = await tradeQueryZFB({ orderNo: this.orderNoZFB })
          if (res.data.status == 'TRADE_SUCCESS') {
            this.clearAllTimer()
            // this.$message.success('支付成功')
            this.centerDialogVisible = false;   //关闭支付二维码弹框
            if (this.type == 1) {
              this.buyDialogVisible = false;      //关闭购买弹框
              this.buyscccessDialogVisible = true;  //支付成功弹框打开
            } else if (this.type == 2) {
              this.$alert('期待与您相聚在我们的线下课程', '购买成功', {
                confirmButtonText: '确定',
              });
              this.dialogVisible = false
            }

          }
        }, 2000)
      }
    },
    dialogClose() {
      this.qianHtml = ''
      this.clearAllTimer()
    },
    // 定时器每过1秒参数减1
    Time() {
      this.paytimer = window.setInterval(() => {
        this.seconds -= 1
        this.countDown()
      }, 1000)
    },
    // 清除所有计时器的公用方法
    clearAllTimer() {
      window.clearInterval(this.timer)
      this.timer = null
      window.clearInterval(this.paytimer)
      this.paytimer = null
      window.clearInterval(this.timerZFB)
      this.timerZFB = null
    },
    // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60))
      d = d < 10 ? '0' + d : d
      let h = parseInt((this.seconds / (60 * 60)) % 24)
      h = h < 10 ? '0' + h : h
      let m = parseInt((this.seconds / 60) % 60)
      m = m < 10 ? '0' + m : m
      let s = parseInt(this.seconds % 60)
      s = s < 10 ? '0' + s : s
      this.count = m + '分' + s + '秒'
      if (this.count == '00分00秒') {
        this.clearAllTimer()
        document.getElementById('qrCode').innerHTML = ''
        this.resetPayflag = true
        this.payTimeFlag = false

      }
    },
    handclick(item) {
      // this.$router.push({ path: "/courseitem", query: { courseId: item.courseId, type: item.type, } })
      this.redrus(item.type, item.courseId)
    },
    redrus(type, courseId) {
      this.type = type
      this.courseId = courseId
      this.loading = true;
      // this.videoSrc = process.env.VUE_APP_BASE_API + '/system/file/video/'+courseId,
      document.body.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start'
      })
      getCourseDetail({ courseId: this.courseId }).then( async res => {
        this.loading = false
        res.data.courseImageUrl = process.env.VUE_APP_BASE_API + res.data.courseImageUrl
        res.data.headSculptureUrl = process.env.VUE_APP_BASE_API + res.data.headSculptureUrl
        res.data.courseIntroduction = JSON.parse(res.data.courseIntroduction)
        if (res.data.courseVideoUrl) {
          res.data.courseVideoUrl = process.env.VUE_APP_BASE_API + res.data.courseVideoUrl
        }
        await  axios.get(res.data.courseVideoUrl, { withCredentials: true })

        this.$nextTick(() => {
              this.player = videojs(this.$refs.videoPlayer)
              this.player.autoplay(false)
              //初始化视频播放器
              this.player.playlist(
                [
                  {
                    name: 'trailer',//视频标题
                    description: 'Explore the depths of our planet\'s oceans.',//视频描述

                    duration: 52,//视频总时长，单位秒(s)
                    sources: [{//视频资源地址以及视频的MIME类型
                      src: res.data.courseVideoUrl,
                      type: 'application/x-mpegurl'
                    }]
                  },]);
              this.player.playlistUi();

            })
        this.sourceDetail = res.data
      })
    },

    getOtherList() {
      getCourseList().then(res => {
        this.resourcelist = []
        const arr = [3, 7, 11, 15]
        this.resourcelist = res.data.filter(item => item.top == 1).map((item, index) => {
          return {
            ...item,
            courseImageUrl: process.env.VUE_APP_BASE_API + item.courseImageUrl,
            startTime: item.startTime.substr(0, 10),
            isfour: arr.includes(index) ? true : false
          }
        })



      })
    }
  }
}
</script>

<style lang="scss" scoped>
.fah {
  position: relative;
}
.mbl {
  width: 100%;
  height: 600px;
  position: absolute;
  top: 0;
  left: 0;
  /* background: rgba(0, 0, 0,0.8); */
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(4px);
  filter: blur(2px);
}

.vid {
  width: 70%;
  // height: 600px;/
  margin: auto;
  position: relative;
  top: -320px;
}

.kecName,
.kcJianj {
  width: 70%;
  margin: auto;
  position: relative;
  top: -270px;
}

.kcJianj {
  // top: 20px;
}

.bti span:nth-child(1) {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}

.bti span:nth-child(2) {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #747474;
}

.bti span:nth-child(3) {
  font-size: 10px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #747474;
  float: right;
  margin-bottom: 5px;
}

.jiage {
  padding-left: 10px;
  margin: 15px 0;
  width: 100%;
}

.jiage span:nth-child(1) {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff0000;
}

.jiage del {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #747474;
  margin-left: 10px;
}

.jiage div {
  width: 14%;
  height: 50px;
  background-color: #12ae96;
  line-height: 50px;
  text-align: center;
  color: #ffffff;
  border-radius: 30px;
  cursor: pointer;
  float: right;
  margin-top: -10px;
}

.lsjj {
  margin-top: 20px;
}
.lsjj .tuoyuan {
  width: 35px;
  vertical-align: sub;
}
.lsjj span {
  font-family: PingFang SC;
  font-weight: 500;
  color: #2f2f2f;
}

.tchr {
  width: 50%;
  margin-top: 50px;
  padding-left: 2%;
  position: relative;
}

.tchr img {
  border-radius: 50%;
}

.tchr span {
  position: absolute;
  top: 40%;
  left: 25%;
  font-size: 18px;
}

.tchrJianj span {
  padding-left: 2%;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #989898;
  line-height: 30px;
}

.kcjj {
  line-height: 40px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #989898;
  padding-left: 2%;
  margin-top: 20px;
}

.kctj {
  // display: flex;
  // justify-content: space-between;
  margin-top: 30px;
  padding-left: 2%;
}

:deep(.el-dialog) {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
  margin-top: 0vh !important;
}

:deep(.el-input__inner) {
  background-color: #e5e5e5;
  height: 53px;
  line-height: 53px;
  font-family: PingFang SC;
  font-weight: 500;
  color: black;
}

.tc2bag {
  background-color: #12b098 !important;
  color: #fff;
}

.tcbtn {
  width: 58%;
  height: 60px;
  line-height: 60px;
  background-color: #12ae96;
  text-align: center;
  border-radius: 10px;
  color: #ffffff;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  position: absolute;
  left: 20%;
  margin-top: 10px;
  cursor: pointer;
  z-index: 9;
}
</style>
